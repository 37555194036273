<template>
  <div class="class-list">
    <div class="top-search">
      <div class="search">
        <el-input
          v-model="queryParams.userName"
          placeholder="请输入内训师姓名"
          clearable
          class="search-input"
        ></el-input>
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号码"
          clearable
          class="search-input"
        ></el-input>
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="resetSearchForm">重置</el-button>
      </div>
    </div>
    <table-list
      title="统计报表"
      :loading="loading"
      :data="TableData"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div slot="filter" class="subTitle">
        <el-date-picker
          v-model="time"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleChooseTimeData"
        >
        </el-date-picker>
        <el-select v-model="timeCheck" placeholder="请选择时间基准" @change="handleTimeChoose">
          <el-option label="本月" :value="1"></el-option>
          <el-option label="本季度" :value="2"></el-option>
          <el-option label="本年度" :value="3"></el-option>
        </el-select>
      </div>
    </table-list>
    <el-dialog title="授课明细" :visible.sync="dialogVisible" width="750px">
      <div class="title">授课明细</div>
      <div class="close-icon" @click="dialogVisible = false">
        <i class="el-icon-close"></i>
      </div>
      <div class="total">
        <span></span>
        <span class="num">累计授课数：{{ pager1.total }}</span>
      </div>
      <div class="content">
        <table-list
          ref="headTeacherTable"
          :loading="loading"
          :data="CourseList"
          :columns="columns1(this)"
          :pager="pager1"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
        ></table-list>
        <div class="btns">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导出报表数据',
    method: _this.handleTableExport,
  },
]
// 搜索框配置
// const searchForm = () => [
//   {
//     label: '内训师姓名',
//     prop: 'userName',
//     width: 150,
//   },
//   {
//     label: '手机号',
//     prop: 'phone',
//     width: 120,
//   },
// ]
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    label: '内训师名称',
    minWidth: 120,
  },
  {
    prop: 'phone',
    label: '手机号码',
    minWidth: 120,
  },
  {
    prop: 'courseCount',
    label: '线上授课数',
    align: 'right',
    minWidth: 120,
  },
  {
    prop: 'academicHour',
    label: '线上授课课时',
    align: 'right',
    minWidth: 130,
  },
  {
    prop: 'courseFee',
    label: '每课时课酬（元）',
    align: 'right',
    minWidth: 160,
    formatter: row => {
      return '￥' + row.courseFee
    },
  },
  {
    prop: 'sumCourseFee',
    label: '总计课酬（元）',
    align: 'right',
    minWidth: 120,
    formatter: row => {
      return '￥' + row.sumCourseFee
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '授课明细',
    method: _this.handleCourseInfo,
  },
]
// 弹窗表格列配置
const columns1 = () => [
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'academicHour',
    label: '课时',
  },
  {
    prop: 'courseFee',
    label: '每课时课酬（元）',
    minWidth: 100,
    formatter: row => {
      return '￥' + row.courseFee
    },
  },
  {
    prop: 'sumCourseFee',
    label: '小计课酬（元）',
    minWidth: 100,
    formatter: row => {
      return '￥' + row.sumCourseFee
    },
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: 120,
  },
]
import TableList from '@/components/TableList'
import { tableTrainerData, TrainerExport, TrainerTeachingDetail } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'TrainerTable',
  components: {
    TableList,
  },
  data() {
    return {
      // searchForm,
      btns,
      columns,
      operates,
      loading: false,
      TableData: [],
      selectTableData: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {
        startTime: '',
        endTime: '',
        timeCheck: null,
        userName: '',
        phone: '',
      },
      timeCheck: null,
      time: [],
      CourseList: [],
      columns1,
      dialogVisible: false,
      pager1: {
        total: 0,
        size: 10,
        current: 1,
      },
      dialogForm: {
        startTime: '',
        endTime: '',
        timeCheck: 0,
        id: 0,
      },
    }
  },
  mounted() {
    this.getTrainerData()
  },
  methods: {
    async getTrainerData() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(tableTrainerData({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.TableData = res.data.list
    },
    // 查询按钮
    onSearch() {
      this.pager.current = 1
      // if (queryParams.userName) {
      //   this.queryParams.userName = queryParams.userName
      // }
      // if (queryParams.phone) {
      //   this.queryParams.phone = queryParams.phone
      // }
      if (this.time.length !== 0) {
        this.queryParams.startTime = this.time[0]
        this.queryParams.endTime = this.time[1]
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      if (this.timeCheck !== null) {
        this.queryParams.timeCheck = this.timeCheck
      }
      this.getTrainerData()
    },
    //重置
    resetSearchForm() {
      this.time = []
      this.queryParams.userName = ''
      this.queryParams.phone = ''
      this.queryParams.startTime = ''
      this.queryParams.endTime = ''
      this.queryParams.timeCheck = null
      this.timeCheck = null
    },
    handleChooseTimeData() {
      this.timeCheck = null
      this.queryParams.timeCheck = null
    },
    handleTimeChoose() {
      this.time = []
      this.queryParams.startTime = ''
      this.queryParams.endTime = ''
    },
    //导出
    async handleTableExport() {
      let ids = []
      if (this.selectTableData.length) {
        this.selectTableData.map(item => ids.push(item.id))
      }
      if (this.time.length !== 0) {
        this.queryParams.startTime = this.time[0]
        this.queryParams.endTime = this.time[1]
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      if (this.timeCheck !== null) {
        this.queryParams.timeCheck = this.timeCheck
      }
      const [res, err] = await to(TrainerExport({ ids: ids, ...this.queryParams }))
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '统计报表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    //授课明细
    handleCourseInfo(row) {
      this.dialogVisible = true
      this.dialogForm.id = row.id
      if (this.time.length !== 0) {
        this.dialogForm.startTime = this.time[0]
        this.dialogForm.endTime = this.time[1]
        this.dialogForm.timeCheck = 0
      }
      if (this.timeCheck !== null) {
        this.dialogForm.timeCheck = this.timeCheck
        this.dialogForm.startTime = ''
        this.dialogForm.endTime = ''
      }
      this.getTableData()
    },
    async getTableData() {
      if (this.loading) return
      const { current, size } = this.pager1
      this.loading = true
      const [res, err] = await to(TrainerTeachingDetail({ current, size, ...this.dialogForm }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager1.total = res.data.total
      this.CourseList = res.data.list
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTableData = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getTrainerData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getTrainerData()
    },
    // 弹窗分页
    handleSizeChange1(size) {
      this.pager1.current = 1
      this.pager1.size = size
      this.getTableData()
    },
    handleCurrentChange1(current) {
      this.pager1.current = current
      this.getTableData()
    },
  },
}
</script>
<style lang="scss" scoped>
.class-list {
  ::v-deep.table-list {
    .el-range-editor.el-input__inner {
      margin-right: 40px;
    }
  }
  .top-search {
    display: flex;
    background: #fff;
    .search {
      padding: 20px 20px;
      .search-input {
        width: 250px;
        margin-right: 20px;
      }
    }
  }
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-left: 40px;
  }
  ::v-deep.el-dialog {
    position: relative;
    width: 860px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 55px;
      line-height: 55px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .total {
      display: flex;
      margin-top: 20px;
      .num {
        margin-left: 15px;
        font-size: 16px;
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
        font-weight: 400;
        color: #e86c2c;
      }
    }
    .content {
      padding: 20px 10px 120px 10px;
      position: relative;
      > .btns {
        text-align: right;
        padding-top: 30px;
        position: absolute;
        right: 10px;
        bottom: 20px;
      }
      :last-child {
        &.table-list {
          overflow-y: auto;
          height: 540px;
        }
      }
      .el-pagination {
        position: absolute;
        right: 10px;
        bottom: 80px;
      }
    }
  }
}
</style>
